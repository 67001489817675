import styled from '@emotion/styled';
import _ from 'lodash';
import { NextPage } from 'next';
import Link from 'next/link';
import { NextRouter, useRouter } from 'next/router';
import { useState } from 'react';
import { HiOutlineChevronDown, HiOutlineChevronRight } from 'react-icons/hi';
import { Media } from 'src/interface/media';
import useResponsive from 'src/utils/devices';
import { splitRote } from 'src/utils/helper';

type Props = {
  fontColor?: string;
  bgColor?: string;
  border?: string;
  data?: any;
  link?: string;
  tabs?: boolean;
  setDataItem?: any;
  setActive?: any;
  dataItem?: any;
  active?: any;
  className?: string;
  showReadmore?: boolean;
  style?: string;
};
const SectionHeader: NextPage<Props> = ({
  fontColor = '',
  bgColor = '',
  border = 'left',
  data,
  link,
  tabs = false,
  setDataItem,
  setActive,
  dataItem,
  active,
  className = '',
  showReadmore = true,
  style = ''
}) => {
  const responsive: Media = useResponsive();
  const router: NextRouter = useRouter();
  if (_.isEmpty(data)) return null;
  const [toggleBar, setToggleBar] = useState(false);

  return (
    <>
      <SectionWrapper fontColor={fontColor} bgColor={bgColor} border={border}>
        {style === '' && (
          <>
            {tabs && data ? (
              <div className='heading'>
                <div className='heading-active'>
                  <a href={`${splitRote(router)}${dataItem.link || '#'}`}>
                    <p className=''>{dataItem?.title || 'ข่าวล่าสุด'}</p>
                  </a>
                </div>
                {responsive.isLaptop && (
                  <ul className='heading-click desktop'>
                    {_.map(data, (item, index) => (
                      <li
                        key={index}
                        onClick={() => {
                          setDataItem(data[index]);
                          setActive({ key: index, status: true });
                        }}
                      >
                        <p className={`${active.status && active.key === index ? 'current' : ''}`}>{item.title}</p>
                      </li>
                    ))}
                  </ul>
                )}
                {responsive.isMobile && (
                  <div className='heading-bar'>
                    <div className='heading-bar--title' onClick={() => setToggleBar(!toggleBar)}>
                      <p>{dataItem?.title || 'ข่าวล่าสุด'}</p>
                      {toggleBar ? <HiOutlineChevronDown /> : <HiOutlineChevronRight />}
                    </div>
                    {toggleBar && (
                      <ul className='heading-click--absolute mobile'>
                        {_.map(data, (item, index) => (
                          <li
                            key={index}
                            onClick={() => {
                              setDataItem(data[index]);
                              setActive({ key: index, status: true });
                            }}
                          >
                            <p className={`${active.status && active.key === index ? 'current' : ''}`}>{item.title}</p>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                )}
              </div>
            ) : (
              <div className={`heading ${className}`}>
                <div className='heading-active'>
                  <a href={`${splitRote(router)}${link || '#'}`}>
                    <p className=''>{data || 'ข่าวล่าสุด'}</p>
                  </a>
                </div>
                {showReadmore && (
                  <div className='readmore'>
                    <Link href={`${splitRote(router)}${link || '#'}`}>
                      <small>อ่านทั้งหมด</small>
                    </Link>
                  </div>
                )}
              </div>
            )}
          </>
        )}
        {style === 'default' && (
          <div className={`heading ${className} default`}>
            <div className='heading-active'>
              <a href={`${splitRote(router)}${link || '#'}`}>
                <p className=''>{data || 'ข่าวล่าสุด'}</p>
              </a>
            </div>
          </div>
        )}
      </SectionWrapper>
    </>
  );
};

export default SectionHeader;

const SectionWrapper = styled.div<{ fontColor: string; bgColor: string; border: string }>`
  .heading {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.content {
      position: relative;
      background: var(--white-color);
      border-bottom: 1px solid var(--border-color);
      &::before {
        content: '';
        position: absolute;
        bottom: -1px;
        background-color: var(--tertiary-color);
        height: 1px;
        width: 150px;
      }
      .heading-active {
        background-color: var(--white-color);
        padding: 8px 16px 8px 0;
        a,p {
          color: var(--black-color);
          font-size: 18px;
          font-weight: 700;
        }
      }
    }
    &.default {
      border-bottom: unset !important;
      .heading-active {
        padding: 0;
        background-color: transparent;
        a , p{
          font-size: 28px;
          font-weight: 700;
          color: var(--black-color) !important;
          @media (max-width: 690px) {
            font-size: 18px;
            color: var(--black-color) !important;
          }
        }
      }
    }
    &.sub-menu {
      background: transparent;
      border-bottom: 1px solid var(--border-color);
      .heading-click {
        li {
          a {
            p {
              color: ${props => props.fontColor || 'var(--border-color)'};
              font-weight: 500;
              font-size: 14px;
            }
          }
        }
      }
    }
    .heading-active {
      /* border-radius: ${props => (props.border == 'left' ? ' 18px 0px 0 0' : ' 0px 18px 0 0')}; */
      background: ${props => props.bgColor || 'var(--tertiary-color)'};
      padding: 8px 16px;
      a {
        font-size: 14px;

        color: ${props => props.fontColor || 'var(--black-color)'};
      }
    }
    .heading-bar {
      .heading-bar--title {
        display: flex;
        align-items: center;
        color: var(--white-color);
        opacity: 0.85;
        svg {
          margin: 2px 8px 0;
        }
      }
    }
  }
`;
